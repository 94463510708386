import { Box, Button, Container, styled } from "@mui/material";
import React, { useState } from "react";
import pic1 from "../Media/up.png";
import pic2 from "../Media/down.png";
import { useTranslation } from "react-i18next";
import DialogComp from "./DialogComp";

export default function ReviewsCard({ feedback, swiper }) {
  const Circle = styled("span")(({ theme }) => ({
    display: "inline-block",
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: "black",
    marginRight: theme.spacing(1),
  }));
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const handleReadMore = (selectedData) => {
    setOpen(true);
    setData(selectedData);
  };

  const handleMouseEnter = () => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box
        sx={{
          minHeight: "200px",
          width: "400px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          bgcolor: "white",
          position: "relative",
          borderRadius: "20px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <img
          src={pic1}
          style={{
            position: "absolute",
            width: "30px",
            height: "20px",
            top: "20px",
            left: "20px",
          }}
        />
        <img
          src={pic2}
          style={{
            position: "absolute",
            width: "30px",
            height: "20px",
            bottom: "20px",
            right: "20px",
          }}
        />
        <Container maxWidth="xs" sx={{ textAlign: "center", p: "20px" }}>
          <p dangerouslySetInnerHTML={{ __html: feedback.feedback.substring(0, 100) }}></p>
          {feedback.feedback !== null && feedback.feedback.length > 99 && (
            <Button
              sx={{ bgcolor: "transparent", color: "#1490CA" }}
              onClick={() =>
                handleReadMore({
                  feedback: feedback.feedback,
                  co_name: feedback.company_name,
                  file: feedback.logo,
                })
              }
            >
              {t("Read More")}
            </Button>
          )}
          <p style={{ fontWeight: "bold" }}>
            {feedback.company_name} <Circle />
          </p>
        </Container>
      </Box>

      <DialogComp open={open} setOpen={setOpen} data={data} />
    </div>
  );
}
