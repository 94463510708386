import { Button, Container, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
// import "swiper/css/navigation";

export default function Introduction({ slider }) {
  const matches = useMediaQuery("(min-width:600px)");

  //   const swiperStyles = `
  // @media (max-width: 500px) {
  //   .swiper-button-next,
  //   .swiper-button-prev {
  //     display: none;
  //   }
  // }

  // .swiper-button-next{
  //     color: white;
  //     right:80px
  // }
  // .swiper-button-prev {
  //   color: white;
  //   left:80px
  // }
  // `;
  function isImage(url) {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];

    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  }

  return (
    <div
      style={{
        marginTop: matches ? "100px" : "80px",
        backgroundColor: "#FFF3EB",
        padding: matches ? "40px" : "20px",
      }}
      id="Introduction"
    >
      {/* <style>{swiperStyles}</style> */}
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        loop
        slidesPerView={1}
        style={{ width: "100%" }}
      >
        {slider.map((s) => (
          <SwiperSlide
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Stack
              direction="row"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: matches ? "center" : "center",
                alignItems: "center",
              }}
              gap={2}
            >
              <Container
                maxWidth="sm"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: matches ? "start" : "center",
                  alignItems: "center",
                }}
              >
                <Stack>
                  <p
                    style={{
                      color: "#1596D0",
                      fontWeight: "bold",
                      fontSize: "30px",
                    }}
                  >
                    {s.title}
                  </p>
                  <p style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: s.description }}></p>
                  <Button
                    sx={{
                      color: "white",
                      borderRadius: "50px",
                      width: "fit-content",
                      height: "30px",
                      textTransform: "none",
                      fontSize: "13px",
                      bgcolor: "#E38137",
                      p: "10px",
                      "&:hover": {
                        bgcolor: "#E38137",
                        color: "white",
                      },
                      mt: "20px",
                    }}
                    target="_blank"
                    href={s.button_action}
                  >
                    {s.button_text}
                  </Button>
                </Stack>
              </Container>
              {isImage(s.media) ? (
                <img
                  src={s.media}
                  style={{
                    width: matches ? "400px" : "250px",
                    objectFit: matches ? "cover" : "cover",
                    borderRadius: "20px",
                  }}
                />
              ) : (
                <video
                  src={s.media}
                  style={{
                    width: matches ? "400px" : "250px",
                    objectFit: matches ? "cover" : "cover",
                    borderRadius: "20px",
                    height:'400px'
                  }}
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  playsInline
                  muted
                />
              )}
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
