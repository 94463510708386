import { Box, Container } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ContactUs from "../Components/ContactUs";
import ContactIcon from "../Components/ContactIcon";
import LoadingPage from '../Components/LoadingPage'
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
    const [open, setOpen] = useState(true);
    const [privacy, setPrivacy] = useState('');
    const lang = localStorage.getItem("lang") || 'en';
    const { t } = useTranslation();

    useEffect(()=>{
   window.scrollTo(0, 0);
   axios
   .get(`${process.env.REACT_APP_API_URL}privacy`, {
     headers: {
       "Accept-Language": lang,
     },
   }).then(res=>{
    setPrivacy(res.data.data.privacy)
    setOpen(false)
   })
  
    },[])
  return (
    <div style={{ marginTop: "100px" }}>
      {open && <LoadingPage open={open} />}
      {!open &&<>
      <Box
        sx={{
          height: "200px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          bgcolor:'#E38137'
        }}
      >
        <p style={{fontWeight:'bold',color:'white',fontSize:'30px'}}>{t('Privacy Policy')}</p>
      </Box>

      <Container maxWidth='md' sx={{padding:'30px'}}>
        <p dangerouslySetInnerHTML={{ __html: privacy }}></p>
        </Container>

        <ContactUs />

        <ContactIcon />
        </>}
    </div>
  );
}
