import { Container, Stack, useMediaQuery } from "@mui/material";
import React from "react";

export default function Annoncement({annoncement}) {
  const matches = useMediaQuery("(min-width:500px)");

  return (
    <div
      style={{
        minHeight: "200px",
        background: "linear-gradient(to bottom, #FFF3EB 50%, white 50%)",
        padding: "50px",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          bgcolor: "white",
          minHeight: "200px",
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            p: matches?"30px":'10px',
          }}
        >
          <img
            src={annoncement.media}
            style={{
              width: matches ? "250px" : "100%",
              height: "150px",
              borderRadius: "20px",
              objectFit:'cover'
            }}
          />

          <Container maxWidth="xs">
            <p
              style={{
                color: "#1596D0",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {annoncement.title}
            </p>
            <p style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: annoncement.description }}>
            </p>
          </Container>
        </Stack>
      </Container>
    </div>
  );
}
