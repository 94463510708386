import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from '@mui/icons-material/Menu';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import logo from "./Media/logo.png";
import axios from "axios";
import LoadingPage from "./Components/LoadingPage";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [pages, setPages] =React.useState(['Introduction'])
  const [open, setOpen] =React.useState(true)
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const lang = localStorage.getItem("lang") || 'en';
  const [lan, setLan] = React.useState("");

  const [activePage, setActivePage] = React.useState("Home");
  React.useEffect(() => {
    if (lang === "ar") {
      setLan(false);
    } else {
      setLan(true);
    }
  }, []);
  React.useEffect(()=>{
    axios.get(`${process.env.REACT_APP_API_URL}home`, {
      headers: {
        "Accept-Language": lang,
      },
    }).then(res=>{
      const activeSections = res.data.data.sections.filter(section => section.is_active);
      const pageKeys = activeSections.map(section => section.key);
      setPages(pageKeys.slice(0,pageKeys.length-1));
      setPages(current => ['Introduction',...current]);
      setOpen(false)
    })
  },[lang])
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();
  const handleNavigation = (page) => {
    let pageInEnglish;
    switch (page) {
      case t('Introduction'):
        pageInEnglish = 'Introduction';
        break;
        case t('products'):
        pageInEnglish = 'products';
        break;
      case t('features'):
        pageInEnglish = 'features';
        break;
        case t('industries'):
        pageInEnglish = 'industries';
        break;
        case t('clients'):
        pageInEnglish = 'clients';
        break;
        case t('faqs'):
          pageInEnglish = 'faqs';
          break;
      default:
        pageInEnglish = page;
        break;
    }
    
    if (lang === 'ar'){
      const sectionElement = document.getElementById(pageInEnglish);
      if (sectionElement) {
        const headerHeight = 90;
        const sectionOffset = sectionElement.offsetTop - headerHeight;
        window.scrollTo({ top: sectionOffset, behavior: "smooth" });
      } 
      else {
        navigate("/");
      }
    }
    const mainPagePath = "/";
    const isMainPage = window.location.pathname === mainPagePath;
    setAnchorElNav(null);
    if (!isMainPage) {
      navigate("/");
    } else {
      const sectionElement = document.getElementById(page);
      if (sectionElement) {
        const headerHeight = 90;
        const sectionOffset = sectionElement.offsetTop - headerHeight;
        window.scrollTo({ top: sectionOffset, behavior: "smooth" });
      } else {
        navigate("/");
      }
    }
  };

  const handleGetInTouch = () => {
    const sectionElement = document.getElementById("support");
    if (sectionElement) {
      const headerHeight = 100;
      const sectionOffset = sectionElement.offsetTop - headerHeight;
      window.scrollTo({ top: sectionOffset, behavior: "smooth" });
      setAnchorElNav(null);
    }
  };

  const handleEn = () => {
    i18next.changeLanguage("en");
    localStorage.setItem("lang", "en");
    setLan(true);
    window.location.reload(true);
  };

  const handleAr = () => {
    i18next.changeLanguage("ar");
    localStorage.setItem("lang", "ar");
    setLan(false);
    window.location.reload(true);
  };


  return (
    <>
    {open && <LoadingPage open={open}/>}
    {!open &&<>
    <AppBar
      position="fixed"
      sx={{
        p: "15px",
        bgcolor: "white",
        boxShadow: "0 4px 2px -2px #C4C3C4C2",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logo} style={{ width: "80px" }} />
          </Typography>

          <Box
            sx={{
              flexGrow: 10,
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button
                    textAlign="center"
                    sx={{ color: "black" }}
                    onClick={() => {
                      handleNavigation(page);
                    }}
                  >
                    {t(page)}
                  </Button>
                </MenuItem>
              ))}
 {lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    letterSpacing: "0px",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"Almarai", sans-serif',
                    ml: "10px",
                  }}
                  onClick={handleAr}
                >
                  العربية
                </Button>
              )}
              {!lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"League Spartan", sans-serif',
                    mr: "10px",
                  }}
                  onClick={handleEn}
                >
                  English
                </Button>
              )}
              <div
                style={{
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    color: "white",
                    borderRadius: "50px",
                    width: "150px",
                    height: "30px",
                    textTransform: "none",
                    fontSize: "13px",
                    bgcolor: "#E38137",
                    "&:hover": {
                      bgcolor: "#E38137",
                      color: "white",
                    },
                  }}
                  onClick={handleGetInTouch}
                >
                  {t('Get Started')}
                </Button>
              </div>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0.5 }}>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: "35px" }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  sx={{
                    my: 2,
                    display: "block",
                    color: page === activePage ? "#E38137" : "black",
                    textTransform: "none",
                    fontSize: "13px",
                    textTransform:'capitalize'
                  }}
                  onClick={() => {
                    handleNavigation(page);
                    setActivePage(page);
                  }}
                >
                  {t(page)}
                </Button>
              ))}
               {lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    letterSpacing: "0px",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"Almarai", sans-serif',
                    ml: "10px",
                  }}
                  onClick={handleAr}
                >
                  العربية
                </Button>
              )}
              {!lan && (
                <Button
                  sx={{
                    my: 2,
                    display: "block",
                    color: "black",
                    textTransform: "none",
                    fontSize: "13px",
                    fontFamily: '"League Spartan", sans-serif',
                    mr: "10px",
                  }}
                  onClick={handleEn}
                >
                  English
                </Button>
              )}
              <Button
                sx={{
                  color: "white",
                  borderRadius: "50px",
                  width: "150px",
                  height: "30px",
                  mt: "15px",
                  textTransform: "none",
                  fontSize: "13px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  bgcolor: "#E38137",
                  "&:hover": {
                    bgcolor: "#E38137",
                    color: "white",
                  },
                }}
                onClick={handleGetInTouch}
              >
                {t('Get Started')}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </>}
    </>
  );
}
export default Navbar;
