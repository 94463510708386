import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import ReviewsCard from './ReviewsCard';

export default function Contracts({client}) {
  const swiperRef = useRef(null);

  return (
    <div style={{marginTop:'50px'}}>
    <Swiper
     modules={[Autoplay]}
     spaceBetween={50}
     slidesPerView="4"
     loop={true}
     autoplay={{ delay: 0, disableOnInteraction: false }}
     speed={5000}
     freeMode={true}
    >
      {client.map((client, index) => (
        <SwiperSlide key={index} style={{ width: 'auto' ,padding:'20px', display:'flex', flexDirection:'column', minWidth:'400px', justifyContent:'center',alignItems:'center'}}>
          <img src={client.logo} style={{ height: '150px', width: '250px', objectFit:'cover', marginBottom:'20px' }}/>
          <ReviewsCard feedback={client} swiper={swiperRef.current?.swiper} />
        </SwiperSlide>
      ))}

    </Swiper>
    </div>
  );
}
