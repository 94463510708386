import { Alert, Box, Button, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldComp from "./TextFieldComp";
import axios from "axios";
import Success from "./Success";
import { useTranslation } from "react-i18next";
import {
  CountryDropdown,
} from "react-country-region-selector";

export default function ContactUs({}) {
  const [solution, setSolution] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [coName, setCoName] = useState("");
  const [job, setJob] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [product, setProduct] = useState([]);
  const [alerting, setAlerting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [sections, setSections] = useState([]);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}home`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setProduct(res.data.data.products);
        setSections(res.data.data.sections);
      });
  }, []);
  const isSectionActive = (key) => {
    const section = sections.find((section) => section.key === key);
    return section && section.is_active;
  };
  const getSectionTitle = (key) => {
    const section = sections.find((section) => section.key === key);
    return section ? section.title : "";
  };
  const getSectionDesc = (key) => {
    const section = sections.find((section) => section.key === key);
    return section ? section.description : "";
  };

  const handleSend = () => {
    if (
      firstName &&
      lastName &&
      solution &&
      email &&
      coName &&
      job &&
      country &&
      message
    ) {
      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("company_name", coName);
      formData.append("work_email", email);
      formData.append("job_title", job);
      formData.append("country", country);
      formData.append("product_id", solution);
      formData.append("message", message);
      setAlerting(false);
      axios
        .post(`${process.env.REACT_APP_API_URL}support-form`, formData, {
          headers: {
            "Accept-Language": lang,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpenSuccess(true);
            setTimeout(() => {
              window.location.reload(true);
            }, 2100);
          }
        });
    } else {
      setAlerting(true);
    }
  };

  return (
    <div id="support">
      {isSectionActive("contact") && (
        <>
          <Container maxWidth="md" sx={{ mt: "50px" }}>
            <Stack
              direction="row"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
              gap={2}
            >
              <Container maxWidth="xs">
                <p
                  style={{
                    color: "#1596D0",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  {getSectionTitle("contact")}
                </p>
                <p>{getSectionDesc("contact")}</p>
                {product.map((p) => (
                  <li>{p.title}</li>
                ))}
              </Container>
              <Box sx={{ bgcolor: "#FFF3EB", p: "20px", width: "350px" }}>
                <p
                  style={{
                    color: "#1596D0",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {t("Get started")}
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={20}>
                    <TextFieldComp
                      label="Choose a solution"
                      value={solution}
                      onChange={setSolution}
                      width={"100%"}
                      h={"40px"}
                      data={product}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldComp
                      label="First Name*"
                      value={firstName}
                      onChange={setFirstName}
                      width={"100%"}
                      h={"40px"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldComp
                      label="Last Name*"
                      value={lastName}
                      onChange={setLastName}
                      width={"100%"}
                      h={"40px"}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldComp
                      label="Company Name*"
                      value={coName}
                      onChange={setCoName}
                      width={"100%"}
                      h={"40px"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldComp
                      label="Work Email*"
                      value={email}
                      type={"email"}
                      onChange={setEmail}
                      width={"100%"}
                      h={"40px"}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldComp
                      label="Job Title*"
                      value={job}
                      onChange={setJob}
                      width={"100%"}
                      h={"40px"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <TextFieldComp
                  label="Country"
                  value={country}
                  onChange={setCountry}
                  width={"100%"}
                  h={"40px"}
                /> */}
                    <CountryDropdown
                      value={country}
                      onChange={(val) => setCountry(val)}
                      style={{
                        width: "100%",
                        height: "40px",
                        marginTop: "10px",
                        border: "none",
                        color: !country ? "#BFBFBF" : "black",
                        outline:'none'
                      }}
                      defaultOptionLabel={t("Country")}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={20}>
                    <TextFieldComp
                      label="Message"
                      value={message}
                      onChange={setMessage}
                      width={"100%"}
                      h={"100px"}
                    />
                  </Grid>
                </Grid>
                {alerting && (
                  <Alert
                    severity="error"
                    sx={{ width: "90%", marginTop: "20px" }}
                  >
                    {t("All fields must be filled.")}
                  </Alert>
                )}
                <Button
                  sx={{
                    color: "white",
                    borderRadius: "50px",
                    width: "150px",
                    height: "30px",
                    textTransform: "none",
                    fontSize: "13px",
                    bgcolor: "#E38137",
                    p: "10px",
                    "&:hover": {
                      bgcolor: "#E38137",
                      color: "white",
                    },
                    mt: "20px",
                  }}
                  onClick={handleSend}
                >
                  {t("Submit")}
                </Button>
              </Box>
            </Stack>
          </Container>
          <Success
            open={openSuccess}
            setOpen={setOpenSuccess}
            msg={"Your message has been successfully send."}
          />
        </>
      )}
    </div>
  );
}
