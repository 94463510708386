import { Box, Button, Container, Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import TabsComponent from "./TabsComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Products({ product , title , desc}) {
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();

  const [data, setData] = useState(product[0]);
  const navigate = useNavigate()

  function isImage(url) {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];

    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  }
  return (
    <div style={{ marginTop: "40px" }} id="products">
      <Container maxWidth="md">
        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
          gap={2}
        >
          <p
            style={{
              color: "#1596D0",
              fontWeight: "bold",
              fontSize: "25px",
              
            }}
          >
            {title}
          </p>
          <Container maxWidth="xs">
            <p
              style={{
                color: "black",
              }}
            >
             {desc}
            </p>
          </Container>
        </Stack>

        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            bgcolor: "white",
            minHeight: "200px",
            borderRadius: "20px",
            p: "20px",
            mt:'20px'
          }}
        >
              <TabsComponent array={product} setData={setData} />
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
            gap={2}
          >
            <Stack sx={{ width: "300px" }}>
              <p
                style={{
                  color: "#1596D0",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                {data.title}
              </p>
              <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            {data.benefits.length > 0 && <><p style={{ fontWeight: "bold" }}>{t('Benefits')}</p>
            {data.benefits.map((item) => (
              <p style={{ color: "#1490CA", fontWeight: "bold", margin:'10px 0px 0px 0px' }}>
                {item.title}
              </p>
            ))}</>}
              <Button
                sx={{
                  color: "white",
                  borderRadius: "50px",
                  width: "150px",
                  height: "30px",
                  textTransform: "none",
                  fontSize: "13px",
                  bgcolor: "#E38137",
                  p: "10px",
                  "&:hover": {
                    bgcolor: "#E38137",
                    color: "white",
                  },
                  mt: "20px",
                }}
                target="_blank"
                onClick={()=>{navigate('/details/'+data.id)}}
              >
                {t('View Details')}
              </Button>
            </Stack>
            {isImage(data.media) ? (
                <img
                  src={data.media}
                  style={{
                    width: matches ? "400px" : "250px",
                    objectFit: matches ? "cover" : "cover",
                    borderRadius: "20px",
                    marginTop:'20px'
                  }}
                />
              ) : (
                <video
                  src={data.media}
                  style={{
                    width: matches ? "400px" : "250px",
                    objectFit: matches ? "cover" : "cover",
                    borderRadius: "20px",
                    height:'400px',
                    marginTop:'20px'

                  }}
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  playsInline
                  muted
                />
              )}
          </Stack>
        </Box>
      </Container>
    </div>
  );
}
