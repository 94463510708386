import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { Container, Stack, useMediaQuery } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ".././App.css";

export default function DetailsFeatrues({ data, isImage }) {
  const matches = useMediaQuery("(min-width:880px)");

  return (
    <div style={{ marginTop: "50px", position:'relative' }}>
      <Swiper
        modules={[Autoplay, Navigation]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        navigation={{
          prevEl: ".custom-swiper-prev",
          nextEl: ".custom-swiper-next",
        }}
        loop
        slidesPerView={1}
        style={{ width: "100%", position: "relative" }}
      >
        {data.map((d) => (
          <SwiperSlide
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container maxWidth="lg">
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "20px",
                }}
                gap={10}
              >
                {isImage(d.media) ? (
                  <img
                    src={d.media}
                    style={{
                      width: matches ? "350px" : "250px",
                      objectFit: matches ? "cover" : "cover",
                      borderRadius: "20px",
                    }}
                  />
                ) : (
                  <video
                    src={d.media}
                    style={{
                      width: matches ? "350px" : "250px",
                      objectFit: matches ? "cover" : "cover",
                      borderRadius: "20px",
                      height: "300px",
                    }}
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    playsInline
                    muted
                  />
                )}
                <Container maxWidth='sm'>
                  <p
                    style={{
                      color: "#1596D0",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    {d.title}
                  </p>
                  <p
                    style={{ color: "black" }}
                    dangerouslySetInnerHTML={{
                      __html: d.description,
                    }}
                  >
                    
                  </p>
                </Container>
              </Stack>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>

      {matches && (
        <>
          <div className="custom-swiper-prev">
            <ArrowLeftIcon
              style={{
                color: "#E38137",
                fontSize: "70px",
                position: "absolute",
                top: "50%",
                left: "10px",
                zIndex: "1",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="custom-swiper-next">
            <ArrowRightIcon
              style={{
                color: "#E38137",
                fontSize: "70px",
                position: "absolute",
                top: "50%",
                right: "10px",
                zIndex: "1",
                cursor: "pointer",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
