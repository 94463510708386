import { HashRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import MainPage from "./Pages/MainPage";
import Footer from "./Footer";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { useEffect } from "react";
import Details from "./Pages/Details";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
  const lang = localStorage.getItem('lang');
  const theme = createTheme({
    typography: {
      fontFamily: '"Inter", sans-serif',
    }
  });
  useEffect(() => {
    if (!lang) {
      localStorage.setItem('lang', 'en');
    }
  }, []);
  return (
    <div>
    <HashRouter>
    <ThemeProvider theme={theme}>
      <Navbar />
    <Routes>
    <Route path="/" element={<MainPage />} />
    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
    <Route path="/details/:id" element={<Details />} />
      </Routes>
      <Footer />
      </ThemeProvider>
    </HashRouter>
    </div>
  );
}

export default App;
