import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingPage from "../Components/LoadingPage";
import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import DetailsFeatrues from "../Components/DetailsFeatrues";
import DetailsBenefits from "../Components/DetailsBenefits";
import DetailsWork from "../Components/DetailsWork";
import ContactUs from "../Components/ContactUs";
import ContactIcon from "../Components/ContactIcon";

export default function Details() {
  const [data, setData] = useState();
  const [open, setOpen] = useState(true);
  const url = window.location.href;
  const id = url.split("/").pop();
  const matches = useMediaQuery("(min-width:700px)");
  const lang = localStorage.getItem("lang");

  function isImage(url) {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];

    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}product-details?id=${id}`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setOpen(false);
      });
  }, []);

  return (
    <div style={{ marginTop: "100px" }}>
      {open && <LoadingPage open={open} />}
      {!open && (
        <>
          <div
            style={{
              display: matches ? "" : "flex",
              alignItems: matches ? "" : "center",
              justifyContent: matches ? "" : "center",
            }}
          >
            {data && isImage(data.header_media) ? (
              <img
                src={data.header_media}
                style={{
                  height: "400px",
                  position: "relative",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                src={data.header_media}
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline
                muted
                style={{
                  height: "400px",
                  position: "relative",
                  width: "100%",
                  objectFit: "cover",
                  position: "relative",
                }}
              />
            )}
            <Box
              sx={{
                bgcolor: "#00000038",
                position: "absolute",
                minHeight: "150px",
                top: "180px",
                borderRadius: "20px",
                left: matches ? "15%" : "",
                backdropFilter: "blur(15px)",
                m: matches ? "" : "10px",
              }}
            >
              <Container maxWidth="xs" sx={{ p: "20px" }}>
                <p
                  style={{
                    color: "white",
                    fontSize: "30px",
                    fontWeight: "bold",
                  }}
                >
                  {data.main_title}
                </p>
                <p
                  style={{ color: "white" }}
                  dangerouslySetInnerHTML={{
                    __html: data.main_description,
                  }}
                >
                </p>
              </Container>
            </Box>
          </div>
          <div
            style={{
              backgroundColor: "#FFF3EB",
              paddingBottom: "40px",
              paddingTop: "20px",
            }}
          >
            <Container maxWidth="lg">
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "start",
                  alignItems: "center",
                }}
                gap={2}
              >
                <p
                  style={{
                    color: "#1596D0",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  {data.inner_title_one}
                </p>
                <Container maxWidth="sm">
                  <p
                    style={{
                      color: "black",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data.inner_description_one,
                    }}
                  ></p>
                </Container>
              </Stack>

              {data && isImage(data.inner_media_one) ? (
                <img
                  src={data.inner_media_one}
                  style={{
                    height: "350px",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    marginTop: "20px",
                  }}
                />
              ) : (
                <video
                  src={data.inner_media_one}
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  playsInline
                  muted
                  style={{
                    height: "350px",
                    position: "relative",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    marginTop: "20px",
                  }}
                />
              )}
            </Container>
          </div>

          <DetailsFeatrues data={data.features} isImage={isImage} />
          <DetailsBenefits data={data.benefits} isImage={isImage} />
          <DetailsWork data={data.work} />

          <Container maxWidth="md">
            <p
              style={{
                color: "#1596D0",
                fontWeight: "bold",
                fontSize: "25px",
                textAlign: "center",
              }}
            >
              {data.inner_title_two}
            </p>

            {data && isImage(data.inner_media_two) ? (
              <img
                src={data.inner_media_two}
                style={{
                  height: matches ? "400px" : "",
                  position: "relative",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                src={data.inner_media_two}
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline
                muted
                style={{
                  height: "400px",
                  position: "relative",
                  width: "100%",
                  objectFit: "cover",
                  position: "relative",
                }}
              />
            )}
          </Container>
        </>
      )}

      <ContactUs />

      <ContactIcon />
    </div>
  );
}
