import React, { useEffect, useState } from "react";
import ContactIcon from "../Components/ContactIcon";
import Annoncement from "../Components/Annoncement";
import Introduction from "../Components/Introduction";
import Products from "../Components/Products";
import Features from "../Components/Features";
import axios from "axios";
import LoadingPage from "../Components/LoadingPage";
import Industries from "../Components/Industries";
import Client from "../Components/Client";
import FAQs from "../Components/FAQs";
import ContactUs from "../Components/ContactUs";

export default function MainPage() {
  const [open, setOpen] = useState(true);
  const [slider, setSlider] = useState([]);
  const [annoncement, setAnnoncement] = useState();
  const [faqs, setFaqs] = useState([]);
  const [product, setProduct] = useState([]);
  const [feature, setFeature] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [client, setClient] = useState([]);
  const [sections, setSections] = useState([]);
  const lang = localStorage.getItem("lang") || 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}home`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setSlider(res.data.data.sliders);
        setAnnoncement(res.data.data.announcement);
        setFaqs(res.data.data.faqs);
        setProduct(res.data.data.products);
        setFeature(res.data.data.features);
        setIndustry(res.data.data.industries);
        setClient(res.data.data.clients);
        setSections(res.data.data.sections);
        setOpen(false);
      })
  }, [lang]);
  const isSectionActive = (key) => {
    const section = sections.find((section) => section.key === key);
    return section && section.is_active;
  };
  const getSectionTitle = (key) => {
    const section = sections.find(section => section.key === key);
    return section ? section.title : "";
  };
  const getSectionDesc = (key) => {
    const section = sections.find(section => section.key === key);
    return section ? section.description : "";
  };
  
  return (
    <div>
      {open && <LoadingPage open={open} />}
      {!open && (
        <>
          <Introduction slider={slider} />
          <Annoncement annoncement={annoncement} />
          {isSectionActive("products") && <Products product={product} title={getSectionTitle("products")} desc={getSectionDesc("products")}/>}
          {isSectionActive("features") && <Features feature={feature} title={getSectionTitle("features")} />}
          {isSectionActive("industries") && <Industries industry={industry} title={getSectionTitle("industries")} desc={getSectionDesc("industries")}/>}
          {isSectionActive("clients") && <Client client={client} title={getSectionTitle("clients")}/>}
          {isSectionActive("faqs") && <FAQs faqs={faqs} title={getSectionTitle("faqs")}/>}
          <ContactUs />
          <ContactIcon />
        </>
      )}
    </div>
  );
}
