import { Box, Button, Container, Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import TabsComponent from "./TabsComponent";

export default function Industries({industry, title, desc}) {
  const matches = useMediaQuery("(min-width:600px)");

  const [data, setData] = useState(industry[0]);
  function isImage(url) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg']; 
  
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(extension);
  }
  return (
    <div style={{ marginTop: "40px",backgroundColor:'#FFF3EB',paddingBottom:'80px' }} id="industries">
      <Container maxWidth="md">
        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",
          }}
          gap={2}
        >
          <p
            style={{
              color: "#1596D0",
              fontWeight: "bold",
              fontSize: "25px",
              width:'350px'
            }}
          >
           {title}
          </p>
          <Container maxWidth="xs">
            <p
              style={{
                color: "black",
              }}
            >
             {desc}
            </p>
          </Container>
        </Stack>

        <Box
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            bgcolor: "white",
            minHeight: "200px",
            borderRadius: "20px",
            p: "20px",
            mt:'20px'

          }}
        >
         
          <TabsComponent array={industry} setData={setData} />
          <Stack
            direction="row"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
            gap={2}
          >
            <Stack sx={{width:'300px'}}>
              <p
                style={{
                  color: "#1596D0",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
               {data.title}
              </p>
              <p dangerouslySetInnerHTML={{ __html: data.description }}>
              </p>
            </Stack>

            {isImage(data.media) ? (
                <img
                  src={data.media}
                  style={{
                    width: matches ? "400px" : "250px",
                    objectFit: matches ? "cover" : "cover",
                    borderRadius: "20px",
                    marginTop:'20px'
                  }}
                />
              ) : (
                <video
                  src={data.media}
                  style={{
                    width: matches ? "400px" : "250px",
                    objectFit: matches ? "cover" : "cover",
                    borderRadius: "20px",
                    height:'400px',
                    marginTop:'20px'
                  }}
                  autoPlay={true}
                  loop={true}
                  controls={false}
                  playsInline
                  muted
                />
              )}
          </Stack>

        </Box>
      </Container>
    </div>
  );
}
