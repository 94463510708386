import { Container, useMediaQuery } from '@mui/material'
import React from 'react'
import FirstStack from './FirstStack'
import SecondStack from './SecondStack'

export default function Features({feature, title}) {

  function isImage(url) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg']; 
  
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(extension);
  }
  return (
    <div style={{marginTop:'100px'}} id='features'>
        <Container maxWidth='md'>
      <Container sx={{textAlign:'center'}} maxWidth='sm'>
      <p
                style={{
                  color: "#1596D0",
                  fontWeight: "bold",
                  fontSize: "30px",
                }}
              >
               {title}
              </p>
      </Container>

      <hr />
      {feature.map((f,index)=> <>
      {index % 2 !== 0 ? 
      <FirstStack f={f} isImage={isImage} /> :
      <SecondStack f={f} isImage={isImage} /> }
      </>)}
      </Container>
    </div>
  )
}
