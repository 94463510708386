import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import pic from '../Media/Group.png'

export default function ContactIcon() {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const matches = useMediaQuery("(min-width:800px)");

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        position: "fixed",
        zIndex: 10,
        top: matches ? "85%" : "89%",
        right: matches ? "50px" : "1px",
      }}
    >
      <a target="_blank" href={`https://wa.me/${localStorage.getItem('number')}`}>
        <img
          style={{
            height:'70px',
            cursor: "pointer",
          }}
          src={pic}
        />
      </a>
    </div>
  );
}
