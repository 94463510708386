import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import logo from "./Media/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingPage from "./Components/LoadingPage";
import bg from "./Media/vid.mp4";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer() {
  const lang = localStorage.getItem("lang") || "en";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [general, setGeneral] = useState();
  const [open, setOpen] = useState(true);
  const matches = useMediaQuery("(min-width:500px)");
  const [product, setProduct] = useState([]);
  const [industry, setIndustry] = useState([]);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}home`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((res) => {
        setGeneral(res.data.data.general);
        setProduct(res.data.data.products);
        setIndustry(res.data.data.industries);
        localStorage.setItem("number", res.data.data.general.phone);
        setOpen(false);
      });
  }, []);
  const handleMailClick = () => {
    window.open(`mailto:${general.email}`);
  };
  const handleGetInTouch = () => {
    const sectionElement = document.getElementById("support");
    if (sectionElement) {
      const headerHeight = 100;
      const sectionOffset = sectionElement.offsetTop - headerHeight;
      window.scrollTo({ top: sectionOffset, behavior: "smooth" });
    }
  };
  return (
    <div>
      {open && <LoadingPage open={open} />}
      {!open && (
        <>
          <div style={{ position: "relative", display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap"}}>
            <video
              src={bg}
              style={{
                marginTop: "50px",
                width: "100%",
                objectFit:'cover',
                height:'350px'
              }}
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted
            />
            <Container
              maxWidth="xs"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                position: "absolute",
                top: "30%",
                zIndex: 10,
              }}
            >
              <p className="slogan">{general.slogan}</p>

              <Button
                sx={{
                  color: "white",
                  borderRadius: "50px",
                  width: "150px",
                  height: "30px",
                  textTransform: "none",
                  fontSize: "13px",
                  bgcolor: "#E38137",
                  p: "10px",
                  "&:hover": {
                    bgcolor: "#E38137",
                    color: "white",
                  },
                  mt: "10px",
                }}
                onClick={handleGetInTouch}
              >
                {t("Get in touch")}
              </Button>
            </Container>
          </div>
          <div style={{ padding: "10px" }}>
            <footer
              style={{ backgroundColor: "#25282E", borderRadius: "10px" }}
            >
              <Container sx={{ pt: "40px", paddingBottom: "50px" }}>
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: matches ? "start" : "start",
                    justifyContent: matches ? "start" : "center",
                  }}
                  gap={matches ? 12 : 5}
                >
                  <Stack
                    sx={{
                      display: matches ? "" : "flex",
                      flexWrap: matches ? "" : "wrap",
                      alignItems: matches ? "start" : "center",
                      justifyContent: matches ? "" : "center",
                    }}
                  >
                    <Typography variant="h6" noWrap component="a" href="/">
                      <img src={logo} style={{ width: "150px" }} />
                    </Typography>
                    <Container sx={{ width: "250px" }}>
                      <p
                        style={{
                          fontSize: "20px",
                          textAlign: matches ? "start" : "center",
                          color: "white",
                        }}
                      >
                        {general.footer_slogan}
                      </p>
                    </Container>
                  </Stack>

                  <Stack sx={{ mt: "20px" }}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Products")}
                    </Typography>
                    {product.length > 0 &&
                      product.map((p) => (
                        <p
                          style={{
                            color: "#9F9F9F",
                            fontSize: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/details/" + p.id);
                            window.location.reload(true);
                            window.scrollTo(0, 0);
                          }}
                        >
                          {p.title}
                        </p>
                      ))}
                    <p
                      style={{
                        color: "white",
                        fontSize: "15px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        navigate("/privacypolicy");
                      }}
                    >
                      {t("Privacy Policy")}
                    </p>
                  </Stack>

                  <Stack sx={{ mt: "20px" }}>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Industries")}
                    </Typography>
                    {industry.map((i) => (
                      <p style={{ color: "#9F9F9F", fontSize: "10px" }}>
                        {i.title}
                      </p>
                    ))}
                  </Stack>

                  <Stack sx={{ mt: "20px" }}>
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {t("Contact Information")}
                    </Typography>
                    <Stack direction="row" gap={2} sx={{ mt: "10px" }}>
                      {general.facebook && (
                        <Link target="_blank" href={general.facebook}>
                          <FacebookIcon
                            sx={{
                              color: "#AAAAAA",
                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                          />
                        </Link>
                      )}
                      {general.instagram && (
                        <Link target="_blank" href={general.instagram}>
                          <InstagramIcon
                            sx={{
                              color: "#AAAAAA",
                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                          />
                        </Link>
                      )}
                      {general.twitter && (
                        <Link target="_blank" href={general.twitter}>
                          <TwitterIcon
                            sx={{
                              color: "#AAAAAA",
                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                          />
                        </Link>
                      )}
                      {general.linkedin && (
                        <Link target="_blank" href={general.linkedin}>
                          <LinkedInIcon
                            sx={{
                              color: "#AAAAAA",
                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                          />
                        </Link>
                      )}
                    </Stack>
                    {general.phone && (
                      <p style={{ color: "#9F9F9F", fontSize: "10px" }}>
                        {general.phone}
                      </p>
                    )}
                    {general.email && (
                      <p
                        style={{
                          color: "#9F9F9F",
                          fontSize: "10px",
                          cursor: "pointer",
                        }}
                        onClick={handleMailClick}
                      >
                        {general.email}
                      </p>
                    )}
                  </Stack>
                </Stack>
              </Container>
            </footer>
          </div>
        </>
      )}
    </div>
  );
}
