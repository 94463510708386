import { Container, Typography } from "@mui/material";
import React, {  useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/material/styles";

export default function FAQs({faqs, title}) {
  const [selected,setSelected] = useState('')
  const matches = useMediaQuery("(min-width:760px)");
  const [expandedIndex, setExpandedIndex] = useState(null);
  const Circle = styled("span")(({ theme}) => ({
    display: "inline-block",
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: '#E38137', 
  }));

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };
  function isImage(url) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg']; 
  
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(extension);
  }
  return (
    <div id="faqs"
    style={{ marginTop: "50px", position: "relative",backgroundColor:'#FFF3EB',paddingTop:'20px',paddingBottom:'20px' }} >
      
        <Typography sx={{ color: "#1490CA", fontSize: "30px",textAlign:'center' , fontWeight:'bold'}}>
       {title}
        </Typography>
     
<Container maxWidth='md'>
      <div className="scroll-container">
        {faqs.map((q, index) => (
          <Accordion
            key={index}
            elevation={0}
            disableGutters
            expanded={expandedIndex === index}
            onChange={() => handleAccordionChange(index)}
            classes={{ root: "accordion-root" }}
            sx={{
              bgcolor: "transparent",
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              "&:before": { display: "none" },
              marginTop:'20px'
            }}
          >
            <AccordionSummary
              expandIcon={
                expandedIndex === index ? (
                  <RemoveIcon sx={{ color: "#1490CA",fontSize:'30px' }} />
                ) : (
                  <AddIcon sx={{ color: "#1490CA",fontSize:'30px' }} />
                )
              }
              sx={{
                bgcolor: "white",
                borderRadius: "10px",
                "&:before": { display: "none" },
                padding: "10px",
                width: matches ? "680px" : "100%",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 4px",
              }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
               <Circle /> {q.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                borderBottom: "1px solid white",
                borderRight: "1px solid white",
                borderLeft: "1px solid white",
                borderRadius: "10px",
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'    ,
               width: matches ? "650px" : "250px",
               boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 4px",
               bgcolor: "white",

              }}
            >
              {q.media && 
              <Container maxWidth="sm">
               {isImage(q.media) ? <img
                  src={q.media}
                  style={{ width: "100%", objectFit: 'cover',height:'300px' }}
                /> : <video
                src={q.media}
                style={{ width: "100%", objectFit: 'cover',height:'300px' }}
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline
                muted
              /> }
              </Container>
              }
              <Container maxWidth="sm">
                <Typography sx={{ color: "black", textAlign: 'center', marginTop: '10px' }}
                              dangerouslySetInnerHTML={{ __html: q.answer }}
                              >
                </Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      </Container>
    </div>
  );
}
