import React from "react";
import Contracts from "./Contracts";
import Reviews from "./Reviews";

export default function Client({ client, title }) {
  return (
    <div style={{ paddingBottom: "50px", paddingTop: "20px" }} id="clients">
      <p
        style={{
          color: "#1596D0",
          fontWeight: "bold",
          fontSize: "30px",
          textAlign: "center",
        }}
      >
        {title}
      </p>
      <Contracts client={client} />
      {/* <Reviews client={client} /> */}
    </div>
  );
}
