import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default function TabsComponent({ array, setData }) {
  const [value, setValue] = useState(0);
  const lang = localStorage.getItem("lang")

  const Circle = styled("span")(({ theme, active }) => ({
    display: "inline-block",
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: active ? "#E38137" : "#1490CA", 
    marginRight: lang === 'en' ? theme.spacing(1) : '0px',
    marginLeft: lang === 'ar' ? theme.spacing(1) : '0px',
  }));
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%", borderBottom: "2px solid #E8E8E8" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            ".MuiTabs-indicator": { backgroundColor: "#E38137" },
            ".MuiButtonBase-root": { color: "black" },
            '.Mui-selected':{color:'black'}
          }}
        >
          {array.map((item, index) => (
            <Tab
            sx={{fontWeight:'bold'}}
              key={index}
              label={
                <Box display="flex" alignItems="center">
                  <Circle active={value === index} />
                  {item.title}
                </Box>
              }
              onClick={()=>{setData(item)}}
            />
          ))}
        </Tabs>
      </Box>
    </div>
  );
}
