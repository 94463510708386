import { Stack, useMediaQuery } from "@mui/material";
import React from "react";

export default function SecondStack({f,isImage}) {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          mt:'20px'
        }}
        gap={10}
      >
        {isImage(f.media) ?
         <img
              src={f.media}
              style={{
                width: matches ? "300px" : "250px",
                objectFit: matches ? "cover" : "cover",
                borderRadius: "20px",
              }}
            />
          : <video
          src={f.media}
          style={{
            width: matches ? "300px" : "250px",
            objectFit: matches ? "cover" : "cover",
            borderRadius: "20px",
            height:'300px'
          }}
          autoPlay={true}
          loop={true}
          controls={false}
          playsInline
          muted
        />}
        <Stack sx={{ maxWidth: "444px" }}>
        <p className="txt"> {f.main_title}</p>
          <p className="title">{f.title}</p>
          <p dangerouslySetInnerHTML={{ __html: f.description }}></p>
        </Stack>
      </Stack>
    </div>
  );
}
