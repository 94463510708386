import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Container, Stack } from "@mui/system";

export default function DialogComp({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      sx={{
        ".MuiPaper-root": {
          bgcolor: "white",
          border: "4px solid #1490CA",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            p: "20px",
          }}
        >
          {data.file && (
            <img
              src={data.file}
              style={{ height: "200px", width: "200px", objectFit: "contain" }}
            />
          )}{" "}
          <Stack>
            <Container maxWidth="sm">
              <p dangerouslySetInnerHTML={{ __html: data.feedback }}></p>
              <p style={{ fontWeight: "bold" }}>{data.co_name}</p>
            </Container>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
